import axios from '@/api/api_config'
import router from '@/router'
import * as jwt from "jsonwebtoken"
//登录方法
export const loginUser = async (login) => {
    return await axios.post('/Users/auth', login)
}
const key = 'tokenDoa'
//从浏览器本地存储获取token值
export const getToken = () => {
    return localStorage.getItem(key)
}
//清除token
export const logOut = () => {
    localStorage.removeItem(key)
    router.replace('login')
}
//设置token不需要在这写，只有登录用到


//检查token过期时间
export const isTokenFromLocalStorageVaild = () => {
    const token = localStorage.getItem(key)
    if (!token) return false
    const decoded = jwt.decode(token)
    //时间戳分为10位（秒级）和13位（毫秒级）
    const DateNow = Date.now()
    const expiresAt = decoded.exp * 1000
    return DateNow <= expiresAt
}
