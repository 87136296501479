import { createRouter, createWebHashHistory } from 'vue-router'
import CreateView from '../views/CreateView.vue'
import RechargeView from '../views/RechargeView.vue'
import ExpendView from '../views/ExpendView.vue'
import ViplistView from '../views/ViplistView.vue'
import VipbillwaterView from '../views/VipbillwaterView.vue'
import { getToken, isTokenFromLocalStorageVaild, logOut } from '@/auth/auth.service'
// import { ElMessage } from "element-plus";
import { showFailToast } from 'vant';
const routes = [
  {
    path: '/login',
    name: 'login',
    // component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/loginadmin',
    name: 'loginadmin',
    component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/',
    name: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/NavigationView.vue'),
    children: [{
      path: '/upload',
      name: 'upload',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/UploadImageView.vue')
    },
    {
      path: '/imagelist',
      name: 'imagelist',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/ImageListView.vue')
    },
    {
      path: '/work',
      name: 'work',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/WorkView.vue')
    },
    {
      path: '/set',
      name: 'set',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/SetView.vue')
    },
    {
      path: '/create',
      name: 'create',
      // component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
      component: CreateView
    },
    {
      path: '/recharge',
      name: 'recharge',
      // component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
      component: RechargeView
    },
    {
      path: '/expend',
      name: 'expend',
      // component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
      component: ExpendView
    },
    {
      path: '/viplist',
      name: 'viplist',
      // component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
      component: ViplistView
    },
    {
        path: '/vipbillwater',
        name: 'vipbillwater',
        // component: () => import(/* webpackChunkName: "about" */ '@/auth/views/UserLoginView.vue')
        component: VipbillwaterView
    }
    
  ]

  }

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// const router = createRouter({
//     mode: "hash",
//     base: process.env.BASE_URL,
//     routes
//   })
//全局守卫（前置/后置）、独享守卫、组件内守卫
router.beforeEach((to, from, next) => {
  //to:可以获取到目的地的路由
  //from:可以获取到从哪里来的路由
  //next():继续、放行(用法1--next()表示继续、2--next("/login")去哪里、3--next(false)取消当前的导航)

  //通过token判断有没有登录
  if (getToken()) {
    //已登录
    if (to.path == "/login") {
      next("/")
    } else {
      //检查token是否过期
      if (isTokenFromLocalStorageVaild()) {
        //未过期
        next()
      } else {
        //已过期
        showFailToast("登录已过期")
        // ElMessage({
        //   message: "登录已过期",
        //   type: "error"
        // })
        logOut()
      }
    }
  } else {
    // next("/login")
    //未登录
    if (to.path == "/login") {
      next()
    } else {
      showFailToast("请先登录")
      // ElMessage({
      //   message: "请先登录",
      //   type: "error"
      // })
      next("/login")
    }
  }
  // next()
})

export default router
