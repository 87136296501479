<template>
    <!-- <van-nav-bar
    title="会员列表"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
  /> -->
    <van-sticky :offset-top="0">
        <van-nav-bar
            title="会员列表"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
            fixed="true"
            placeholder="true"
        />
        <van-cell-group>
            <van-row align="center">
                <van-col span="6">
                    <van-dropdown-menu>
                        <van-dropdown-item
                            v-model="selectValue"
                            :options="optionName"
                        />
                    </van-dropdown-menu>
                </van-col>
                <van-col span="15"
                    ><van-field v-model="state.info" placeholder="请输入信息"
                /></van-col>
                <van-col span="3"
                    ><van-button
                        plain
                        size="small"
                        type="primary"
                        @click="select()"
                        >搜索</van-button
                    ></van-col
                >
                <!-- <van-col span="3"><van-button plain size="small" type="primary" @click="test()">测试</van-button></van-col> -->
            </van-row>
        </van-cell-group>
        <!-- <van-notice-bar left-icon="volume-o" text="无论我们能活多久，我们能够享受的只有无法分割的此刻，此外别无其他。" /> -->
    </van-sticky>

    <!-- <van-sticky :offset-bottom="50" position="bottom"> -->

    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="加载完成"
        @load="onLoad"
        @offset="10"
    >
        <div v-for="(item, index) in state.vipList" :key="index" class="box">
            <div class="row">
                <div class="vipname">{{ item.vipname }}</div>
                <div>注册时间:{{ item.createtime }}</div>
            </div>
            <div class="row">
                <div>手机号:{{ item.telephone }}</div>
                <div class="expend">余额:{{ item.balance }}</div>
            </div>
            <van-divider />
        </div>
    </van-list>
</template>
<script setup>
import { ref, reactive } from 'vue'
import axios from 'axios'
import { showFailToast } from 'vant'
import { showLoadingToast, closeToast } from 'vant'
const loading = ref(false)
const finished = ref(false)
const selectValue = ref(0)
const optionName = [
    { text: '手机号', value: 0 },
    { text: '会员名', value: 1 }
]
const state = reactive({
    info: '',
    vipList: []
})
const init = async () => {
    var table = await axios.get('/Vip/GetVipData').then((res) => {
        state.vipList = res.data
        console.log(state.vipList)
        state.vipList.forEach(function (element) {
            console.log(element)
        })
        loading.value = false
        finished.value = true
    })

    return table
}
const select = async () => {
    if (state.info == '') {
        showFailToast('输入信息为空')
        return
    }
    showLoadingToast({
        duration: 0,
        forbidClick: true,
        message: '加载数据中'
    })
    var table = await axios
        .get(`/Vip/GetVipData/${state.info}&${selectValue.value}`)
        .then((res) => {
            state.vipList = res.data
            // console.log(state.productList[0].createTime);
            // state.productList.forEach(function (element) {
            //     console.log(element);
            // });
            loading.value = false
            finished.value = true
            closeToast()
        })

    return table
}
const onLoad = () => {
    // 异步更新数据
    init()
}

const onClickLeft = () => history.back()
</script>
<style>
.box {
    margin: 0.5rem;
}
.vipname {
    font-size: 2rem;
}
.row {
    display: flex;
    justify-content: space-between;
}
</style>