<template>
    <van-nav-bar
        title="开卡"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        fixed="true"
        placeholder="true"
    />
    <van-form @submit="onSubmit" class="vipcard">
        <van-cell-group inset>
            <van-field
                v-model="vipForms.vipname"
                name="会员名"
                label="会员名"
                placeholder="会员名"
                :rules="[{ required: true, message: '请填写会员名' }]"
            />
            <van-field
                v-model="vipForms.telephone"
                name="pattern"
                label="手机号"
                placeholder="手机号"
                :rules="[
                    { required: true, pattern, message: '请填写正确的手机号' }
                ]"
            />
        </van-cell-group>
        <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">
                提交
            </van-button>
        </div>
    </van-form>
</template>
<script setup>
import { reactive, toRefs } from 'vue'
import { showConfirmDialog, showDialog } from 'vant'
import axios from '@/api/api_config'
import { showLoadingToast, closeToast } from 'vant'
const pattern = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
const onClickLeft = () => history.back()
const state = reactive({
    vipForms: {
        vipname: '',
        telephone: ''
    }
})
const onSubmit = async () => {
    showConfirmDialog({
        title: '确认提交',
        message: '是否要注册？'
    })
        .then(() => {
            showLoadingToast({
                duration: 0,
                forbidClick: true,
                message: '注册中'
            })
            let param = {
                vipname: vipForms.value.vipname,
                telephone: vipForms.value.telephone
            }
            axios.post('/Vip/AddVip', param).then((res) => {
                // showToast(res.data.message);
                closeToast()
                showDialog({
                    message: res.data.message
                }).then(() => {
                    // on close
                })
            })
        })
        .catch((ex) => {
            console.log(ex)
            // on cancel
            if (ex != 'cancel') {
                showDialog({
                    message: ex
                }).then(() => {
                    // on close
                })
            }
        })
}
//解构
const { vipForms } = toRefs(state)
</script>