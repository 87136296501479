import { loginUser } from "@/auth/auth.service"
import router from "@/router"
import { logOut } from "@/auth/auth.service"
import { showSuccessToast, showFailToast } from 'vant';
const authModule = {
    //namespaced告诉使用者调用时需要加上命名空间
    namespaced: true,
    state: {
        //全局变量
        signInState: {
            email: "",
            exp: Date.now(),
            sub: "",
            token: null
        }
    },
    getters: {
        //全局变量的计算属性
    },
    mutations: {
        //修改全局变量
        //修改token的状态
        userLogin(state, token) {
            state.signInState.token = token
            localStorage.setItem("tokenDoa", token)
        }
    },
    actions: {
        //异步修改全局变量
        async userLoginAction({ commit }, login) {
            const { data } = await loginUser(login)
            if (data == null) {
                return
            }
            if (data.message != null) {
                showFailToast(data.message)
                console.log(data);
            }
            else {
                // console.log(data);
                showSuccessToast("登录成功");
                commit('userLogin', data.token)
                router.replace('/')
            }

        },
        logout() {
            //移除token
            logOut()
        }
    },
}
export default authModule;