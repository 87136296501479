import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'



//vant组件
import { Button } from 'vant';
import 'vant/lib/index.css';
import { Tabbar, TabbarItem } from 'vant';
import { Form, Field, CellGroup } from 'vant';
import { Cell } from 'vant';
import { ConfigProvider } from 'vant';
import { NavBar } from 'vant';
import { Space } from 'vant';
import { Uploader } from 'vant';
import { Toast } from 'vant';
import { Pagination } from 'vant';
import { Sticky } from 'vant';
import { Lazyload } from 'vant';
import {
    Skeleton,
    SkeletonTitle,
    SkeletonImage,
    SkeletonAvatar,
    SkeletonParagraph,
} from 'vant';
import { NoticeBar } from 'vant';
import { List } from 'vant';
import { Card } from 'vant';
import { Tag } from 'vant';
import { ImagePreview } from 'vant';
import { Image as VanImage } from 'vant';
import { Col, Row } from 'vant';

import { Watermark } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Grid, GridItem } from 'vant';

import { Dialog } from 'vant';
import { Popup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Divider } from 'vant';
import { Icon } from 'vant';


const app = createApp(App)


app.use(Radio);
app.use(RadioGroup);
app.use(DropdownMenu);
app.use(DropdownItem);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(Icon);
app.use(Divider);
app.use(Popup);
app.use(Dialog);
app.use(Grid);
app.use(GridItem);
app.use(Watermark);
app.use(Col);
app.use(Row);
app.use(VanImage);
app.use(ImagePreview);
app.use(Tag);
app.use(Card);
app.use(List);
app.use(NoticeBar);
app.use(Skeleton);
app.use(SkeletonTitle);
app.use(SkeletonImage);
app.use(SkeletonAvatar);
app.use(SkeletonParagraph);
app.use(Lazyload);
app.use(Sticky);
app.use(Pagination);
app.use(Toast);
app.use(Uploader);
app.use(Space);
app.use(NavBar);
app.use(ConfigProvider);
app.use(Cell);
app.use(CellGroup);
app.use(Form);
app.use(Field);
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);

app.use(ElementPlus).use(store).use(router).mount('#app')