<template>
    <van-nav-bar
        title="消费"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        fixed="true"
        placeholder="true"
    />
    <van-cell-group>
        <van-row align="center">
            <van-col span="6">
                <van-dropdown-menu>
                    <van-dropdown-item
                        v-model="selectValue"
                        :options="optionName"
                    />
                </van-dropdown-menu>
            </van-col>
            <van-col span="15"
                ><van-field v-model="state.info" placeholder="请输入信息"
            /></van-col>
            <van-col span="3"
                ><van-button plain size="small" type="primary" @click="select()"
                    >搜索</van-button
                ></van-col
            >
            <!-- <van-col span="3"><van-button plain size="small" type="primary" @click="test()">测试</van-button></van-col> -->
        </van-row>
    </van-cell-group>
    <div v-if="state.isShowVip" class="vipcard">
        <van-cell-group inset>
            <van-cell
                icon="star-o"
                title="会员名"
                :value="state.vipForm.vipname"
            />
            <van-cell
                icon="phone-o"
                title="手机号"
                :value="state.vipForm.telephone"
            />
            <van-cell
                icon="todo-list-o"
                title="注册时间"
                :value="state.vipForm.createtime"
            />
            <van-cell
                icon="balance-list-o"
                title="余额"
                :value="state.vipForm.balance"
            />
        </van-cell-group>
    </div>
    <van-form @submit="onSubmit" class="vipcard">
        <van-cell-group inset>
            <van-field
                v-model="state.vipbillForm.expend"
                name="消费金额"
                label="消费金额"
                placeholder="消费金额"
                :rules="[{ required: true, message: '请填写消费金额' }]"
            />
        </van-cell-group>
        <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">
                确定
            </van-button>
        </div>
    </van-form>
    <van-popup
        v-model:show="showBottom"
        closeable
        position="bottom"
        :style="{ height: '50%' }"
    >
        <van-radio-group v-model="checkedIndex" @click="confirmVip">
            <div
                v-for="(item, index) in state.vipList"
                :key="index"
                class="vipbox"
            >
                <van-radio :name="index">
                    <div>会员名:{{ item.vipname }}</div>
                    <div>手机号:{{ item.telephone }}</div>
                    <div>余额:{{ item.balance }}</div>
                </van-radio>
            </div>
        </van-radio-group>
    </van-popup>
</template>
<script setup>
import { ref, reactive } from 'vue'
import axios from 'axios'
import { showFailToast } from 'vant'
import {
    showDialog,
    showConfirmDialog,
    showLoadingToast,
    closeToast
} from 'vant'
const selectValue = ref(0)
const optionName = [
    { text: '手机号', value: 0 },
    { text: '会员名', value: 1 }
]
const showBottom = ref(false)
const onClickLeft = () => history.back()
const checkedIndex = ref('1')
const state = reactive({
    isShowVip: false,
    info: '',
    vipForm: {
        id: 0,
        vipname: '',
        telephone: '',
        createtime: '',
        balance: ''
    },
    vipList: [
        {
            id: 0,
            vipname: '',
            telephone: '',
            createtime: '',
            balance: ''
        }
    ],
    vipbillForm: {
        vipid: 0,
        expend: null
    },
    vipbillFormClean: {
        vipid: 0,
        expend: null
    }
})
const select = async () => {
    if (state.info == '') {
        showFailToast('输入信息为空')
        state.vipbillForm = state.vipbillFormClean
        return
    }
    try {
        await axios
            .get(`/Vip/GetVipListsResult/${state.info}&${selectValue.value}`)
            .then((res) => {
                if (res.data.message == 404) {
                    showDialog({
                        message: '无会员信息，请重新查找！'
                    }).then(() => {
                        state.vipbillForm = state.vipbillFormClean
                    })
                    // state.isShowVip = false
                } else {
                    showBottom.value = true
                    state.vipList = res.data.vip
                    // state.vipbillForm.vipid = state.vipForm.id
                    console.log(state.vipList)
                    // state.isShowVip = true
                }

                console.log(res)
            })
    } catch (error) {
        console.log(error)
    }
}
const confirmVip = () => {
    showBottom.value = false
    state.vipForm = state.vipList[checkedIndex.value]
    state.vipbillForm.vipid = state.vipForm.id
    state.isShowVip = true
}
const onSubmit = async () => {
    if (state.vipbillForm.vipid == 0) {
        showDialog({
            message: '会员信息有误，请重新查找！'
        })
    } else {
        showConfirmDialog({
            title: '确认提交',
            message: '是否确认消费？'
        })
            .then(() => {
                showLoadingToast({
                    duration: 0,
                    forbidClick: true,
                    message: '扣费中'
                })
                let param = {
                    vipid: state.vipbillForm.vipid,
                    expend: state.vipbillForm.expend
                }
                axios.post('/Vipbill/VipExpend', param).then((res) => {
                    closeToast()
                    if (res.data.code == 200) {
                        //更新余额
                        state.vipForm.balance = res.data.balance
                        state.vipbillForm.expend = null
                        showDialog({
                            message: res.data.message
                        }).then(() => {
                            // on close
                        })
                    } else if (res.data.code == 500) {
                        showDialog({
                            message: res.data.message
                        }).then(() => {
                            // on close
                        })
                    }
                })
            })
            .catch((ex) => {
                console.log(ex)
                // on cancel
                if (ex != 'cancel') {
                    showDialog({
                        message: ex
                    }).then(() => {
                        // on close
                    })
                }
            })
    }
}
</script>
