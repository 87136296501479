<template>
    <!-- <van-nav-bar
    title="会员列表"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
  /> -->
    <van-sticky :offset-top="0">
        <van-nav-bar
            title="流水账"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
            fixed="true"
            placeholder="true"
        />
        <van-cell-group>
            <van-row align="center">
                <van-col span="6">
                    <van-dropdown-menu>
                        <van-dropdown-item
                            v-model="selectValue"
                            :options="optionName"
                        />
                    </van-dropdown-menu>
                </van-col>
                <van-col span="15"
                    ><van-field v-model="state.info" placeholder="请输入信息"
                /></van-col>
                <van-col span="3"
                    ><van-button
                        plain
                        size="small"
                        type="primary"
                        @click="select()"
                        >搜索</van-button
                    ></van-col
                >
                <!-- <van-col span="3"><van-button plain size="small" type="primary" @click="test()">测试</van-button></van-col> -->
            </van-row>
        </van-cell-group>
        <!-- <van-notice-bar left-icon="volume-o" text="无论我们能活多久，我们能够享受的只有无法分割的此刻，此外别无其他。" /> -->
    </van-sticky>

    <!-- <van-sticky :offset-bottom="50" position="bottom"> -->

    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="加载完成"
        @load="onLoad"
        @offset="10"
    >
        <div
            v-for="(item, index) in state.vipWaterList"
            :key="index"
            class="box"
        >
            <div v-if="item.type === 1">
                <div class="row">
                    <div class="vipname">{{ item.vipname }}</div>
                    <div>充值时间:{{ item.createtime }}</div>
                </div>
                <div class="row">
                    <div>手机号:{{ item.telephone }}</div>
                    <div class="recharge">
                        <van-icon name="gold-coin-o" />充值金额:{{
                            item.recharge
                        }}
                    </div>
                </div>
                <van-divider />
            </div>
            <div v-if="item.type === 2">
                <div class="row">
                    <div class="vipname">{{ item.vipname }}</div>
                    <div>消费时间:{{ item.createtime }}</div>
                </div>
                <div class="row">
                    <div>手机号:{{ item.telephone }}</div>
                    <div class="expend">
                        <van-icon name="cart-circle-o" />消费金额:{{
                            item.expend
                        }}
                    </div>
                </div>
                <van-divider />
            </div>
        </div>
    </van-list>
</template>
<script setup>
import { ref, reactive } from 'vue'
import axios from 'axios'
import { showLoadingToast, closeToast } from 'vant'
// import { showFailToast } from 'vant'
import { showDialog } from 'vant'
const loading = ref(false)
const finished = ref(false)
const selectValue = ref(0)
const optionName = [
    { text: '手机号', value: 0 },
    { text: '会员名', value: 1 }
]
const state = reactive({
    info: '',
    vipWaterList: [
        {
            type: 1,
            vipname: '测试',
            telephone: '13627878787',
            createtime: '2023-10-19 11:01:40',
            recharge: '200+100',
            expend: 0
        },
        {
            type: 2,
            vipname: '测试2',
            telephone: '13627878787',
            createtime: '2023-10-19 11:01:40',
            recharge: '0+0',
            expend: 200
        },
        {
            type: 1,
            vipname: '测试3',
            telephone: '13627878787',
            createtime: '2023-10-19 11:01:40',
            recharge: '200+100',
            expend: 0
        }
    ]
})
const init = async () => {
    await axios.get('/Vipbill/Vipbillwater100').then((res) => {
        state.vipWaterList = res.data.vipWaterList
        loading.value = false
        finished.value = true
    })

    // return table
}
const select = async () => {
    showLoadingToast({
        duration: 0,
        forbidClick: true,
        message: '加载数据中'
    })
    if (state.info == '') {
        await axios.get('/Vipbill/Vipbillwaterall').then((res) => {
            state.vipWaterList = res.data.vipWaterList
            loading.value = false
            finished.value = true
            closeToast()
        })
        return
    }
    console.log('KKK')
    await axios
        // .get(`/Vip/GetVipListsResult/${state.info}&${selectValue.value}`)
        .get(`/Vipbill/VipbillwaterWhere/${state.info}&${selectValue.value}`)
        .then((res) => {
            if (res.data.Code == 404) {
                showDialog({
                    message: res.data.message
                })
                closeToast()
            } else {
                state.vipWaterList = res.data.vipWaterList
            }
            closeToast()
            // console.log(state.productList[0].createTime);
            // state.productList.forEach(function (element) {
            //     console.log(element);
            // });
            loading.value = false
            finished.value = true
        })
}
const onLoad = () => {
    // 异步更新数据
    init()
}

const onClickLeft = () => history.back()
</script>
<style>
.box {
    margin: 0.5rem;
}
.vipname {
    font-size: 2rem;
}
.row {
    display: flex;
    justify-content: space-between;
}
.recharge {
    color: #ffd700;
}
.expend {
    color: #108ee9;
}
</style>