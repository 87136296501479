import axios from "axios";
import { getToken } from "@/auth/auth.service";
import { showFailToast } from 'vant';
axios.defaults.baseURL = "/api"
// axios.defaults.baseURL = "http://localhost:8080/api/"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'application/json'
//配置拦截器
//请求拦截
// const config = {
//     baseURL: '/api',
//     timeout: 550000,
// }
// axios.create(config)
axios.interceptors.request.use(options => {
    //为添加token
    const jwtToken = getToken()
    if (jwtToken) {
        options.headers.Authorization = `Beaerer ${jwtToken}`
    }

    return options
})
// 响应拦截
axios.interceptors.response.use(res => {
    //响应成功
    // showSuccessToast(res.data)
    // console.log(res);
    return res
}, error => {
    //响应未成功
    // console.log(error);
    // console.log(error.response.status);
    showFailToast(error);
    // console.log(error);
    // ElMessage({
    //     message: error.response.data.message,
    //     type: "error"
    // })
    // showFailToast(error.response.data.title);
    // showFailToast(error.response.data.message);
    // showToast(error.response.data.message);
    return error
})
export default axios;